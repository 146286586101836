<template>
  <div class="bot">
      <!-- <div class="inner top">
        <span>  ICP备案号：<a href="http://beian.miit.gov.cn" target="_blank">皖ICP备20006424号-1</a></span>
        <a class="topr" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34010402702149" target="_blank" style="text-decoration:none"><span><img src="https://oa-zhcx.oss-cn-hangzhou.aliyuncs.com/website/images/111.png" alt=""> 皖公网安备 34010402702149号</span></a>
        <span><a href="http://beian.miit.gov.cn" target="_blank">网络文化经营许可证</a></span>
      </div> -->
      <div class="inner top">
        <span >ICP备案号：<a href="http://beian.miit.gov.cn" target="_blank">皖ICP备20006424号-1</a></span>
        <span class="topItem"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34010402702149" target="_blank" style="text-decoration:none"><span><img src="https://oa-zhcx.oss-cn-hangzhou.aliyuncs.com/website/images/images/111.png" alt=""> <a style="position:relative;top:-4px">皖公网安备 34010402702149号</a></span></a></span>
        <span >皖网文（2020）2529-121号</span>
      </div>
      <div class="inner bottom">
        <span>公司地址:安徽省合肥市蜀山区望江西路129号五彩商业广场18楼</span>
        <span>Copyright © 2023 中徽畅享科技有限公司 All rights reserved.</span>
      </div> 
  </div>
</template>

<script>
export default {
    name:'Bot'
}
</script>

<style  scoped>
    .bot{
      width: 100%;
      min-width: 1200px;
      height: 162px;
      background: #0D1B29;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .inner{
      position: relative;
      top: 50px;
      display: flex;
    }
    .topItem{
      padding: 0 0px 0 20px;
      border-right: #AAA solid 1px;
      border-left: #AAA solid 1px;
      margin-right: 15px;
      text-align: center;
    }
    .topr{
      position: relative;
      top: 10px;
    }
    span{
        display: block;
        margin-bottom: 18px;
        margin-right: 20px;
        height: 21px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #AAAAAA;
        line-height: 21px;
        text-align: center;
    }
    a{    
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #AAAAAA;
    }

</style>