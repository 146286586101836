//引入Vue
import Vue from "vue";
//引入App
import App from './App';
//引入vue-router
import VueRouter from "vue-router";
import router from './router';
//引入视频插件
import Video from 'video.js'
import 'video.js/dist/video-js.css'
//引入图标组件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';



Vue.prototype.$video = Video;

//关闭Vue的生产提示
Vue.config.productionTip = false;
//全局注册图标组件库

//应用vue-router插件
Vue.use(VueRouter);
Vue.use(ElementUI);

new Vue({
    el: '#app',
    render: h => h(App),
    router
});



