<template>
  <div>
    <Topbar v-if="$route.path!=='/jxs/feedback'" id="topbar"/>
    <div id="body"  >
      <router-view>
      </router-view>
      <Bot/>
      <div @click="top()" id="to_top" ref="toTop" style="display:none"></div>
    </div>
    
  </div>
  
</template>

<script>

import Topbar from './components/TopBarNew.vue';
import Bot from './components/Bot.vue'

export default {
  name: "App",
  components:{
    Topbar,
    Bot
  },
  methods: {
    top(){
      let scrollTop = window.pageYOffset||document.documentElement.scrollTop||document.body.scrollTop;
      const timeTop=setInterval(()=>{
        document.body.scrollTop = document.documentElement.scrollTop=scrollTop-=50;
        if(scrollTop<=0)
        clearInterval(timeTop);
      },10
      )
    },
    scrollToTop(){
      let topBtn = this.$refs.toTop;
      let scrollTop = window.pageYOffset||document.documentElement.scrollTop||document.body.scrollTop;
      // let browserHeight = window.outerHeight;
      if(scrollTop>1)
      topBtn.style.display = 'block';
      else
      topBtn.style.display = 'none';
    },
  },
  //设置滚动条监听
  mounted(){
    window.addEventListener('scroll',this.scrollToTop);
  },
  destroyed(){
    window.removeEventListener('scroll',this.scrollToTop);
  }
}
</script>
<style scoped>
  #to_top{
    position:fixed;
    right:30px;
    bottom:30px;
    height:72px;
    width:72px;
    background-image: url(./pages/images/black_top.png);
    cursor: pointer;
  }
  #to_top:hover{
    background-image:url(./pages/images/black_top2.png)
  }
  
    @media(min-width: 1930px){
      #body{
        width: 1920px;
        position: relative;
        left: 50%;
        margin-left: -960px;
      }
    }
    /*小屏幕取消导航栏绝对定位 */
    @media(max-width:1210px){
    /*#topbar{*/
    /*  position:initial;*/
    /*  width: 1200px;*/
    /*}*/
    .line{
      position: relative;
      top: -2px;
    }
  }
</style>



