import VueRouter from "vue-router";

export default new VueRouter({
    routes: [
        {
            path: '',
            redirect: '/index'
        },
        {
            path: '/index',
            component: () => import('@/pages/Index')
        },
        {
            path: '/serves',
            component: () => import('@/pages/Serves'),
            children: [
                {
                    path: '',
                    redirect: '/serves/customers'
                },
                {
                    path: 'customers',
                    component: () => import('@/pages/serves/Customers')
                },
                {
                    path: 'wechat',
                    component: () => import('@/pages/serves/WeChat')
                },
                {
                    path: 'tiktok',
                    component: () => import('@/pages/serves/TikTok')
                },
                {
                    path: 'office',
                    component: () => import('@/pages/serves/Office')
                },
                {
                    path: 'communicate',
                    component: () => import('@/pages/serves/Communicate')
                },
                {
                    path: 'message',
                    component: () => import('@/pages/serves/Message')
                },
                {
                    path: 'marketing',
                    component: () => import('@/pages/serves/Marketing')
                },
                {
                    path: 'management',
                    component: () => import('@/pages/serves/Management')
                }
            ]
        },
        {
            path: '/pmm',
            component: () => import('@/pages/production/pmmDetail')
        },
        {
            path: '/fjj',
            component: () => import('@/pages/production/fjjDetail')
        },
        {
            path: '/jxs',
            component: () => import('@/pages/production/jxsDetail'),
            children: [
                {
                    path: 'merchants',
                    component: () => import('@/pages/production/jxsDetail/merchants')
                },
                {
                    path: 'marketing',
                    component: () => import('@/pages/production/jxsDetail/marketing')
                }
            ]
        },
        {
            path: '/news',
            component: () => import('@/pages/News/index'),
            children: [
                {
                    path: '',
                    redirect: '/news/newsList'
                },
                {
                    path: 'newsList',
                    component: () => import('@/pages/News/list')
                },
                {
                    name:'newsDetail',
                    path: 'newsDetail/:id',
                    component: () => import('@/pages/News/detail')
                }
            ]
        },
        {
            path:'/aboutus',
            component:()=>import('@/pages/AboutUs')
        },
        {
            path:'/feedback',
            component:()=>import('@/pages/feedback')
        },
        {
            path:'/jxs/feedback',
            component:()=>import('@/pages/feedback')
        }
    ]
});

